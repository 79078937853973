import React,{useState,useEffect} from "react";
import './HomeAcacia.scss'
const HomeAcacia = () => {
    const [showStiky, setShowStiky] = useState('ocultar')

    useEffect(()=>{
        window.scrollTo(0, 0)
    },
    []
    )
    return ( 
        <div id="amenmapa" className='contenedor_home_acacia_desc'>
            <img className='contenedor_home_acacia_fondo' src="/assets/img-velasur-privada-acacia.jpg" alt="" />
                                <div className='contenedor_home_acacia__slider_card_logo'>
                                <div className="contenedor-home-principal">
                                    <img src="/assets/Logos/svg-logo-acacia.svg" alt="Logo Acacia" />
                                <div className='button_dispo_acacia'>
                                        <a href='https://disponibilidad.velasur.mx/privada-acacia'>VER DISPONIBILIDAD</a>
                                </div>
                                
                                </div>
                            </div>
                            <div className="bottom-info-home">
                            <div className='icon-scroll'></div>
                                <div className='whatsapp'>
                                    <div className={showStiky}>
                                        <a target={'_blank'} href="https://api.whatsapp.com/send?phone=524428801675&text="><img src="/assets/Logos/svg-icon-whatsapp.svg" alt="Whatsapp" /></a>
                                </div>
                                </div>
                                <div className='texto_acacia'>
                                    <p>LOTES RESIDENCIALES PREMIUM</p>
                                </div>
                                </div>
        </div>
     );
}
export default HomeAcacia;