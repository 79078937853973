import React, { useState, useEffect, useCallback } from "react";
import "./Principal.scss";
import Slider from "../Slider/Slider";
import { SwiperSlide } from "swiper/react";

const Principal = () => {
  const [y, setY] = useState(window.scrollY);
  const [showStiky, setShowStiky] = useState("ocultar");

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        if (window.scrollY <= 100) {
          setShowStiky("ocultar");
          console.log(y, "y");
          console.log(window.scrollY, "scrolly");
        }
      } else if (y < window.scrollY) {
        if (window.scrollY >= 100) {
          setShowStiky("mostrar");
          console.log(y, "y");
          console.log(window.scrollY, "scrolly");
        }
      }
      setY(window.scrollY);
    },
    [y]
  );
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <div className="contenedor__principal">
      <div className="contenedor__principal_carousel">
        <Slider nav={false} pag={false}>
          <SwiperSlide>
            <div className="contenedor__principal_carousel__slider">
              <img src="./assets/Principal/1-desk.jpg" alt="Velasur exterior" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contenedor__principal_carousel__slider">
              <img src="./assets/Principal/2-desk.jpg" alt="Velasur exterior" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contenedor__principal_carousel__slider">
              <img src="./assets/Principal/4-desk.jpg" alt="Velasur exterior" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contenedor__principal_carousel__slider">
              <img src="./assets/Principal/5-desk.jpg" alt="Velasur exterior" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contenedor__principal_carousel__slider">
              <img src="./assets/Principal/6-desk.jpg" alt="Velasur exterior" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contenedor__principal_carousel__slider">
              <img
                src="./assets/Principal/img_velasur_9.jpg"
                alt="Velasur exterior"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contenedor__principal_carousel__slider">
              <img
                src="./assets/Principal/img_velasur_8.jpg"
                alt="Velasur exterior"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contenedor__principal_carousel__slider">
              <img
                src="./assets/Principal/img_velasur_3.jpg"
                alt="Velasur exterior"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contenedor__principal_carousel__slider">
              <img
                src="./assets/Principal/img_velasur_13.jpg"
                alt="Velasur exterior"
              />
            </div>
          </SwiperSlide>
        </Slider>
      </div>
      <div className="bottom_info">
        <div className="icon-scroll"></div>
        <div className="whatsapp">
          <div className={showStiky}>
            <a
              target={"_blank"}
              href="https://api.whatsapp.com/send?phone=524428801675&text="
            >
              <img src="/assets/Logos/svg-icon-whatsapp.svg" alt="Whatsapp" />
            </a>
          </div>
        </div>
        <div className="texto">
          <p>LOTES RESIDENCIALES PREMIUM</p>
        </div>
      </div>
    </div>
  );
};

export default Principal;
