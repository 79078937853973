import React,{useState,useCallback, useEffect} from 'react';
import './Header.scss'
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { useNavigate, useLocation } from 'react-router-dom';
const Header = (props) => {

    const [y, setY] = useState(window.scrollY);
    const [scrollMenu, setScroll] = useState('')
    const [logo, setLogo] = useState('/assets/Logos/svg-logo-velasur.svg')
    const navigate = useNavigate();
    const actualUrl = useLocation();
    const handleNavigation = useCallback(
      (e) => {
        const window = e.currentTarget;
        if (y > window.scrollY) {
            if(window.scrollY===0){
                setScroll('')
                setLogo('/assets/Logos/svg-logo-velasur.svg')
           }
        } else if (y < window.scrollY) {
            if(window.scrollY !== 0){
                setScroll('back_menu')
                setLogo('/assets/Iconos/svg-icon-iso.svg')
            }
        }
        setY(window.scrollY);
      },
      [y]
    );
    const scrollTarget = (target) => { 
        console.log('scroll',target)
        scroller.scrollTo(target, {smooth: true, duration: 700})
      };
      
      const scrollToPage =  (target) => {
        console.log('app',target)
        navigate(`/${target}`)
        scrollTarget(target.slice(1));
      };

    useEffect(() => {
      setY(window.scrollY);
      window.addEventListener("scroll", handleNavigation);
  
      return () => {
        window.removeEventListener("scroll", handleNavigation);
      };
    }, [handleNavigation]);

    // useEffect(()=>{
    //     scrollToPage(actualUrl.hash)
    //   },[]);
    const handleMenu = (type) =>{
        console.log("entro")
        if(type) scrollToPage(type)
        props.setOpen(props.open === 'menu-open' ? '' : 'menu-open')
            
    }
    const dispoTemp = () =>{
        props.setOpen(props.open === 'menu-open' ? '' : 'menu-open')
        props.setImagen(true)
    }

    return ( 
        <>
            <header className={`header  ${scrollMenu}`}>
                <div className='icon_menu'>
                    <p className="btn-toggle" onClick={()=> handleMenu()} ><span></span></p> <p>MENÚ</p>
                </div>
                <div className='icon_logo'>
                    <a href="/"><img src={logo} alt="logo" /></a>
                </div>
                <div className='telefono'>
                    <a href="tel:+524428801675" target={'_blank'}>T. (442) 880 1675</a>
                </div>
                <nav className='menu'>
                    <ul>
                        <li><a href="#concepto" onClick={()=> handleMenu('#concepto')}>CONCEPTO</a></li>
                        <li><a>MACROPROYECTO</a>
                            <ul>
                                <li><a href="/privada-acacia">PRIVADA ACACIA</a></li>
                                <li><a href="/privada-artemisa">PRIVADA ARTEMISA</a></li>
                            </ul>
                        </li>
                        <li><a href='#masterplan' onClick={()=> handleMenu('#masterplan')}>DISPONIBILIDAD</a></li>
                        {/* <li><a href="#galeria" onClick={()=> handleMenu()}>GALERIA</a></li> */}
                        <li><a href="/casas-velasur" onClick={()=> handleMenu()}>CASAS</a></li>
                        <li><a href="#ubicacion" onClick={()=> handleMenu()}>UBICACIÓN</a></li>
                        {/* <li><a href="#contacto" onClick={()=> handleMenu()}>CONTACTO</a></li> */}
                        <li><a href="https://grupovelas.com.mx/" target={'_blank'}><img src="/assets/Logos/svg-logo-grupo-velas.svg" alt="Grupo velas" /></a></li>
                    </ul>
                </nav>
                <div className='social-media'>
                    <a href="https://api.whatsapp.com/send?phone=524428801675&text=" target={'_blank'}><img src="/assets/Logos/svg-icon-whatsapp.svg" alt="whatsapp" /></a>
                    <a href="https://www.facebook.com/Velasur-by-Grupo-Velas-100278439225721/" target={'_blank'}><img src="/assets/Logos/svg-icon-facebook.svg" alt="facebook" /></a>
                    <a href="https://instagram.com/velasur_qro?utm_medium=copy_link" target={'_blank'}><img src="/assets/Logos/svg-icon-instagram.svg" alt="instagram" /></a>
                </div>
            </header>
            
        </>
     );
}
 
export default Header;