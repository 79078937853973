import React,{useState} from 'react';
import * as yup from 'yup';
//import axios from 'axios';
import swal from 'sweetalert';
import ReCAPTCHA from "react-google-recaptcha";
import './Modal.scss'
const Modal = (props) => {

    const [nombre, colocarNombre] = useState("")
    const [tel, colocarTel] = useState("")
    const [email, colocarEmail] = useState("")
    const [recaptcha, setreCaptcha] = useState("")
    const [errors, setErrors] = useState([])
    //const [reqname, setRequerido] = useState("")
    //const [reqtel, setReqtel] = useState("")
    //let [res,setRes] = useState({});
    const validationSchema = yup.object({
        nombre: yup.string().required("El nombre es requerido"),
        tel: yup.string()
        .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "El numero de teléfono no es válido"
        ).min(10, "Mínimo 10 dígitos")
        .max(10, "Máximo 10 dígitos").required("El teléfono es requerido"),
        email: yup.string().email(),
        recaptcha: yup.string().required("Resuelva el recaptcha")
    })

    // Obtener el siguiente ID
  const obtenerSiguienteID = async () => {
    try {
      const response = await fetch("https://vistavelasunset.grupovelas.com/get_next_id.php");
      if (!response.ok) throw new Error(`Error HTTP: ${response.status}`);
      const data = await response.json();
      return data.next_id || null;
    } catch (error) {
      console.error("Error al obtener el siguiente ID:", error);
      return null;
    }
  };

  const descargarBrochure = () => {
    const link = document.createElement("a");
    link.href = "/assets/brochure/Brochure23.03.22.pdf";
    link.target = "_blank";
    link.download = "Brochure.pdf";
    link.click();
  };

  const submitForm = async (event) => {
    event.preventDefault();

    const values = { nombre, tel, email, recaptcha };
    const isFormValid = await validationSchema.isValid(values);

    if (!isFormValid) {
      validationSchema.validate(values, { abortEarly: false }).catch((error) => {
        const errorMsgs = error.inner.map((err) => err.errors[0]);
        setErrors(errorMsgs);
      });
      return;
    }

    const siguienteID = await obtenerSiguienteID();
    if (!siguienteID) {
      swal("Error", "No se pudo obtener el siguiente ID.", "error");
      return;
    }

    const urlActual = window.location.href;

    const data = {
      id: siguienteID,
      Nombre: nombre,
      Apellidos: "Sin Apellido",
      Telefono: tel,
      Movil: tel,
      Correo: email,
      Descripcion: "no hay comentario",
      ClaveDesarrollo: "qro-005",
      Campana: "CP-0160",
      Medio: "MPUB-0022",
      CorreoAgente: "erios@velasresorts.com",
      CorreoAgenteTitular: "erios@velasresorts.com",
      TipoPersona: "F",
      referer: urlActual,
    };

    console.log("Enviando datos:", data);

    try {
        // Enviar a vistavelasunset
        const response1 = await fetch("https://vistavelasunset.grupovelas.com/send_lead.php", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });
        const data1 = await response1.json();
        console.log("Respuesta de vistavelasunset:", data1);
      
        // Enviar a velasur
        const response2 = await fetch("https://velasur.mx/php/index.php", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });
        const data2 = await response2.json();
        console.log("Respuesta de velasur:", data2);
      
        if (response1.ok && response2.ok) {
          swal("Enviado", "Los datos han sido enviados correctamente", "success");
      
          // Resetear campos después del envío
          colocarNombre("");
          colocarTel("");
          colocarEmail("");
          setreCaptcha("");
      
          // Descargar el brochure
          descargarBrochure();
        } else {
          throw new Error("Error en la respuesta del servidor");
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
        swal("Error", "Hubo un problema al enviar el formulario.", "error");
      }      
  };
    return ( 
        <div className='contenedor_modal'>
            <p onClick={()=>{props.setModal(false)}} className='closed'>X</p>
            <div className='contenedor_modal_form'>
                <img src="/assets/Logos/svg-logo-velasur.svg" alt="" />
                <p>Déjanos tus datos y descarga nuestro brochure</p>
                <div className="errores">
          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
                <form onSubmit={submitForm} id="Brochure">
                <input type="text" placeholder="Nombre" value={nombre} onChange={(e) => colocarNombre(e.target.value)} required/>
                <input type="text" placeholder="Teléfono" value={tel} onChange={(e) => colocarTel(e.target.value)} required pattern="[0-9]{10}"/>
                <input type="email" placeholder="Correo" value={email} onChange={(e) => colocarEmail(e.target.value)} required/>
                    <p>Al enviar acepta nuestra <a onClick={()=>{props.setModalAviso(true)}}>Politica de privacidad</a></p>
                    <ReCAPTCHA sitekey="6Lcti0YfAAAAAJ82nMtSbh-N19afeVlBRUp10qhB" onChange={setreCaptcha} />
                    <button type="submit" className="button_send">ENVIAR</button>
                </form>
            </div>
        </div>
     );
}
 
export default Modal;