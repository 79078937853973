import React from 'react';
import './Footer.scss'
const Footer = (props) => {
    return ( 
        <div id="contacto" className='contenedor_footer'>
            <div className='contenedor_footer_social'>
                <a href="https://www.facebook.com/Velasur-by-Grupo-Velas-100278439225721/" target={'_blank'}><img src="/assets/Logos/svg-icon-facebook.svg" alt="" /></a>
                <a href="https://instagram.com/velasur_qro?utm_medium=copy_link" target={'_blank'}><img src="/assets/Logos/svg-icon-instagram.svg" alt="" /></a>
            </div>
            <div className='contenedor_footer_logo'>
                <a href="https://grupovelas.com.mx/" target={'_blank'}><img src="/assets/Logos/svg-logo-grupo-velas.svg" alt="" /></a>
            </div>
            <div className='contenedor_footer_tel'>
                <a href="tel:+524428801675" target={'_blank'}>T. (442) 880 1675</a>
            </div>
            <div className='contenedor_footer_info'>
               <p> © 2025 Grupo Velas ® Todos los derechos reservados | <a onClick={()=>{props.setModalAviso(true)}}>Política de privacidad</a></p>
               <p>El vendedor de VelaSur se reserva el derecho de realizar modificaciones en los planos, diseños exteriores, precios, materiales, especificaciones, acabados y características estándar en cualquier momento y sin previo aviso. Las fotografías, las representaciones son ilustrativos y conceptuales.</p>
            </div>
        </div>
     );
}
 
export default Footer;