export const PrototypeTypes = {
  AcaciaI: "Acacia-I",
  AcaciaIV: "Acacia-IV",
  AcaciaXIX: "Acacia-XIX",
};

export const FloorType = {
  LowFloor: "Planta-Baja",
  TopFloor: "Planta-Alta",
  Terrace: "Terraza",
};

const prototypes = {
  [PrototypeTypes.AcaciaI]: {
    name: "ACACIA I",
    terreno: "264",
    construccion: "281.74",
    gallery: [
      "/assets/casas-velasur/Acacia I/img-acacia-i-casas-velasur.jpeg",
      "/assets/casas-velasur/Acacia I/img-recamara-acacia_1-velasur.jpg",
      "/assets/casas-velasur/Acacia I/img-acceso-acacia_1-velasur.jpg",
      "/assets/casas-velasur/Acacia I/img-bano-rec-acacia_1-velasur.jpg",
      "/assets/casas-velasur/Acacia I/img-comedor-acacia_-1.jpg",
      "/assets/casas-velasur/Acacia I/img-comedor-acacia_1-velasur.jpg",
      "/assets/casas-velasur/Acacia I/img-jardin-acacia_1-velasur.jpg",
    ],
    floor: {
      [FloorType.LowFloor]: {
        title: "Planta Baja",
        features: [
          {
            characteristics: "Cocina equipada",
            additional: "",
          },
          {
            characteristics: "Medio baño",
            additional: "",
          },
          {
            characteristics: "Cuarto de servicio",
            additional: "con baño completo",
          },
          {
            characteristics: "Terraza / Jardín",
            additional: "",
          },
          {
            characteristics: "Espacio para 3 autos",
            additional: "",
          },
          {
            characteristics: "Asador",
            additional: "",
          },
          {
            characteristics: "Alberca*",
            additional: "opcional, con costo extra*",
          },
          {
            characteristics: "Pergolado*",
            additional: "opcional, con costo extra*",
          },
        ],
        floorImage:
          "/assets/casas-velasur/Acacia I/img-acacia-i-baja-casas-velasur.jpg",
      },
      [FloorType.TopFloor]: {
        title: "Planta Alta",
        features: [
          {
            characteristics: "3 Recámaras",
            additional: "",
          },
          {
            characteristics: "3 Baños",
            additional: "Incluye: vestidor y balcón*",
          },
        ],
        floorImage:
          "/assets/casas-velasur/Acacia I/img-acacia-i-alta-casas-velasur.jpg",
      },
    },
  },
  [PrototypeTypes.AcaciaIV]: {
    name: "ACACIA IV",
    terreno: "264",
    construccion: "237.82",
    gallery: [
      "/assets/casas-velasur/Acacia iv/img-acacia-iv-casas-velasur.jpeg",
      "/assets/casas-velasur/Acacia iv/img-jardin_b-acacia_iv-velasur.jpg",
      "/assets/casas-velasur/Acacia iv/img-recamara-acacia_iv-velasur.jpg",
      "/assets/casas-velasur/Acacia iv/img-sala_tv-acacia_iv-velasur.jpg",
      "/assets/casas-velasur/Acacia iv/img-sala-acacia_iv-velasur.jpg",
      "/assets/casas-velasur/Acacia iv/img-comedor-acacia_iv-velasur.jpg",
      "/assets/casas-velasur/Acacia iv/img-bano-acacia_iv-velasur.jpg",
      "/assets/casas-velasur/Acacia iv/img-jardin_-acacia_iv-velasur.jpg",
    ],
    floor: {
      [FloorType.LowFloor]: {
        title: "Planta Baja",
        surface: "107.63",
        features: [
          {
            characteristics: "Cocina equipada",
            additional: "",
          },
          {
            characteristics: "1 Baño completo",
            additional: "",
          },
          {
            characteristics: "Cuarto de servicio",
            additional: "con baño completo",
          },
          {
            characteristics: "Terraza / Jardín",
            additional: "",
          },
          {
            characteristics: "Espacio para 3 autos",
            additional: "",
          },
          {
            characteristics: "Sala de tv",
            additional: "",
          },
          {
            characteristics: "Alberca*",
            additional: "opcional, con costo extra*",
          },
          {
            characteristics: "Pergolado*",
            additional: "opcional, con costo extra*",
          },
        ],
        floorImage:
          "/assets/casas-velasur/Acacia iv/img-acacia-iv-alta-casas-velasur.jpg",
      },
      [FloorType.TopFloor]: {
        title: "Planta Alta",
        surface: "117.61",
        features: [
          {
            characteristics: "3 Recámaras",
            additional: "",
          },
          {
            characteristics: "3 Baños",
            additional: "Incluye: vestidor, balcón compartido",
          },
        ],
        floorImage:
          "/assets/casas-velasur/Acacia iv/img-acacia-iv-baja-casas-velasur.jpg",
      },
    },
  },
  [PrototypeTypes.AcaciaXIX]: {
    name: "ACACIA XIX",
    terreno: "264",
    construccion: "263.89",
    gallery: [
      "/assets/casas-velasur/acacia xix/img-acacia-xix-casas-velasur.jpeg",
      "/assets/casas-velasur/acacia xix/img-sala-acacia_xix-velasur.jpg",
      "/assets/casas-velasur/acacia xix/img-recamara-acacia_xix-velasur.jpg",
      "/assets/casas-velasur/acacia xix/img-recamara_pal-acacia_xix-velasur.jpg",
      "/assets/casas-velasur/acacia xix/img-pasillo_patio-acacia_xix-velasur.jpg",
      "/assets/casas-velasur/acacia xix/img-jardin-acacia_xix-velasur.jpg",
      "/assets/casas-velasur/acacia xix/img-cocina-acacia_xix-velasur.jpg",
      "/assets/casas-velasur/acacia xix/img-bar-acacia_xix-velasur.jpg",
    ],
    floor: {
      [FloorType.LowFloor]: {
        title: "Planta Baja",
        surface: "128.65",
        features: [
          {
            characteristics: "Cocina equipada",
            additional: "",
          },
          {
            characteristics: "Bar",
            additional: "",
          },
          {
            characteristics: "Medio baño",
            additional: "",
          },
          {
            characteristics: "Cuarto de lavado",
            additional: "",
          },
          {
            characteristics: "Terraza / Jardín",
            additional: "",
          },
          {
            characteristics: "Espacio para 3 autos",
            additional: "",
          },
          {
            characteristics: "Alberca*",
            additional: "opcional, con costo extra*",
          },
          {
            characteristics: "Pergolado*",
            additional: "opcional, con costo extra*",
          },
        ],
        floorImage:
          "/assets/casas-velasur/acacia xix/img-acacia-xix-alta-casas-velasur.jpg",
      },
      [FloorType.TopFloor]: {
        title: "Planta Alta",
        surface: "124.39",
        features: [
          {
            characteristics: "3 Recámaras",
            additional: "",
          },
          {
            characteristics: "3 Baños",
            additional: "Incluye: vestidor y balcón",
          },
        ],
        floorImage:
          "/assets/casas-velasur/acacia xix/img-acacia-xix-baja-casas-velasur.jpg",
      },
    },
  },
};

export const getPrototype = (prototypeType) => prototypes[prototypeType] ?? {};
