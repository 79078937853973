import React,{useState, useCallback, useEffect} from 'react';
import { scroller } from 'react-scroll';
import './HeroCasas.scss'

const CONTACT_PHONE = "4423228840";

const HeroCasas = () => {

    const [showStiky, setShowStiky] = useState('ocultar')
    const [y, setY] = useState(window.scrollY);
    const handleNavigation = useCallback(
        (e) => {
          const window = e.currentTarget;
          if (y > window.scrollY) {
              if(window.scrollY <= 100){
                setShowStiky('ocultar')
             }
          } else if (y < window.scrollY) {
              if(window.scrollY >= 100){
                setShowStiky('mostrar')
              }
          }
          setY(window.scrollY);
        },
        [y]
      );

    // TODO: Unify this utils in a custom hook
    const scrollTarget = (target) => { 
        scroller.scrollTo(target, {smooth: true, duration: 700})
    };

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
    
        return () => {
          window.removeEventListener("scroll", handleNavigation);
        };
      }, [handleNavigation]);

    return ( 
        <div className='contenedor_hero_casas'>
            <img className='contenedor_hero_casas_fondo' src="/assets/casas-velasur/img-cover-casas-velasur.jpg" alt="" />
            <div className='contenedor_hero_casas__slider_card_logo'>
                <div className="contenedor_hero_casas_principal">
                    <h1>CASAS VELASUR</h1>
                    <div className='button_dispo_acacia'>
                        <a href='#prototipos' onClick={() => scrollTarget('#prototipos')}>VER PROTOTIPOS</a>
                    </div>       
                </div>
            </div>
            <div className="bottom-info-casas">
                <div className='icon-scroll'></div>
                <div className='whatsapp'>
                    <div className={showStiky}>
                        <a target={'_blank'} href="https://api.whatsapp.com/send?phone=524428801675&text="><img src="/assets/Logos/svg-icon-whatsapp.svg" alt="Whatsapp" /></a>
                    </div>
                </div>
                <div className='texto_acacia'>
                    <p>VIVE LA EXCLUSIVIDAD</p>
                </div>
            </div>            
        </div>
     );
}
 
export default HeroCasas;